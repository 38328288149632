.challenge{
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.main-section-challenge{
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 30px;
    font-size: 1.2rem;
}

.left-challenge{
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 1em;
}

/* Solution */

.solution-item1{
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    gap: 150px;
}

.solution-item2{
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    gap: 150px;
}

.solution-img{
    width: 100%;
    height:auto;
    border-radius: 20px;
}

.solution-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
}

.solution-header{
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 1em;
}

.solution-info{
    font-size: 1.20rem;
}

.number{
    border-radius: 50%;
    padding: 5px;
    /* margin-right: auto; */
    text-align: center;
    margin-bottom: 10px;
    width: 35px;
    height: 35px;
    background-color: rgb(199, 199, 205);
}

/* Impact */
.impact-cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.impact-single-card{
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 10px;
    border-radius: 10px;
    border-top: 5px solid #6c41ff;
    background-color: #ffffff;
}

.impact-card-header{
    color: #6c41ff;
    font-weight: 10;
    text-align: center;
}

.impact-card-figure{
    font-weight: bold;
    font-size: 3rem;

}

.impact-card-content{
    /* font-weight: bold; */
    font-size: 1.2rem;
    text-align: center;
    
}

.impact-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
}

.impact-left-section{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 1.2rem;
}

.impact-img{
    border-radius: 20px;
}

@media only screen and (max-width: 768px) {
    .main-section-challenge{
        font-size: 1.2rem;
        display: flex;
        gap: 15px;
        flex-direction: column;
    }

   .impact-section {
       display: flex;
       flex-direction: column;
   }

       
    
        

}

@media only screen and (max-width: 1200px)  {
.solution-item2 {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 10px;

    }

    .solution-item1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
         .impact-cards {
             display: flex;
             flex-direction: column;
             margin: 0 auto;
         }
    
         
}


@media only screen and (max-width: 1200px) and (min-width:768px) {
    .solution-img{
        width: 70%;
        margin: 0 auto;
    }
   .impact-single-card{
    width: 60%;
    margin: 0 auto;
   }

}

@media only screen and (max-width: 600px) {
    .impact-card-content{
        font-size: 1rem;
    }
}




/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
 
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
}