.section-1 {
    height: 25em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    /* Apply fade-down animation to the text */
}

.section-1 h1 {
    color: white;
    font-size: 80px;
    opacity: 0;
    animation: fadeDownText 1.5s forwards;
}

.section-2 {
    background-color: white;
    padding: 5% 10%;
    /* margin-bottom: 10%; */
    align-items: center;
    justify-content: center;
    text-align: center;
}

.section-2 p {
    line-height: 1.4;
    color: rgb(71, 103, 136);
}

.bar {
    width: 150px;
    height: 5px;
    background-color: #6C41FF;
    margin-top: 10px;
    opacity: 0;
    animation: fadeUpBar 1.5s forwards;
    /* Apply fade-up animation to the bar */
}

@keyframes fadeDownText {
    from {
        opacity: 0;
        transform: translateY(-30%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeUpBar {
    from {
        opacity: 0;
        transform: translateY(100px);
        /* Initial position below the container */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeLeftText {
    from {
        opacity: 0;
        transform: translateX(-30%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeRightText {
    from {
        opacity: 0;
        transform: translateX(30%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

