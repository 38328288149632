/* ContactUs.css */

.contact-container {
    margin: 5% 10%;
    background-color: #1d0f33;
}

.contact-section {
    color: white;
    width: 90%;
    margin-bottom: 10%;
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 50px;
    animation: fadeLeftText 1.5s forwards;
}

.contact-info-item {
    display: flex;
    align-items: center;
    gap: 30px;
}

.contact-icon {
    font-size: 10px;
    color: white;
    background-color: rgb(108 65 255);
    width: 70px;
    height: 70px;
    padding: 20px;
    border-radius: 10px;
}

.contactus-form {
    background-color: #301f4d;
    /* Set the opacity to your desired value */
    border: 2px solid transparent;
    padding: 5%;
    /* Optional: Add padding to the box */
    border-radius: 10px;
    color: white;
}

label {
    color: white;
}

.input-border {
    border-color: white !important;
}


/* Responsive Styles */

@media (max-width: 600px) {
    .contact-section h1 {
        font-size: 2.5rem;
        /* Adjust the font size for smaller screens */
    }
}