.polcies-grid-container {
    display: grid;
    grid-template-columns: 1fr 5fr;
    /* background-color: #2196F3; */
    /* padding: 10px; */
  }
  .polcies-grid-item {
    /* background-color: rgba(255, 255, 255, 0.8); */
     background-color: #F7F5FF;
    /* border: 0.5px solid #d32222; */
    
    /* padding: 20px;
    font-size: 30px; */
    /* text-align: center; */
  }