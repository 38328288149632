/* General arrow styles */
.arrow {
  width: 50px; 
  height: 50px; 
  border-radius: 50%;
  background-color: white; 
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; /* Position arrows absolutely within the carousel */
  top: 50%; /* Vertically center the arrows */
  transform: translateY(-50%); /* Center correction */
  z-index: 1; /* Ensure arrows are above the carousel content */
}


/* Arrow size reduction for small screens */
@media only screen and (max-width: 768px) {
  .arrow {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }

  
}

/* Further size reduction for very small screens */
@media only screen and (max-width: 480px) {
  .arrow {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }

  /* Keep arrows even closer to the carousel */
  .arrow.next {
    left: -5px;
  }
  .arrow.prev {
    left: 325px;
  }
}

/* Optional hover effect */
.arrow:hover {
  background-color: #f0f0f0;
}

