.casestudycard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 20px;
    border-radius: 15px;
    background-color: #ffffff;
    padding: 40px 15px;
}

.logocasestudycard{
    font-size: 2rem;
    color: black;
    font-weight: 800;
    align-self: center;
}

.learnmore{
    color: blue;
}

.cases{
    padding: 10px;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: rgba(43, 40, 40, 0.5);
    border-radius: 50%;
    color: white;
    transition: background-color 0.3s ease;
}

.next {
    left: -50px;
}

.prev {
    right: -50px;
}

.arrow:hover {
    background-color: rgba(0, 0, 0, 0.7);
}



