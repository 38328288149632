


.custom-opacity {
  /* Adjust the alpha value for transparency */
  backdrop-filter: blur(10px);
  border: none;
}

ul {
  margin: 0px;
  padding: 0px;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.copyright-area {
  padding: 25px 0;
}

/* .social-links a {
	color: #FFF;
	background-color: rgba(255, 255, 255, 0.2);
	display: inline-block;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	text-align: center;
	margin: 0 10px 10px 0;
	line-height: 40px;
	transition: all 0.5s ease;
} */

.social-links a:hover {
	color: #151515;

}

.footer-subheaders:hover{
border-bottom: green;
}