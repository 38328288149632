.infotagchild{
    
    display: flex;
    gap: 20px;
    align-items: center;
}

.infotag-content{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.infotag-value{
    font-weight: bold;
}

/* .icon{
    font-size: 2rem;
 
   
            color: "#6c41ff";
            width: "40px";
            height: "40px"; 
            padding: "15px";
            border-radius: "10px";
            background-color: "#ffffff";
} */