.section-1 {
    height: 25em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    opacity: 0; /* Initial opacity set to 0 */
    animation: fadeDown 1s forwards; /* Apply animation with a duration of 1 second */
    color: #ffffff;
}

.bar {
    width: 50px;
    height: 3px;
    /* background-color: red; */
    margin-top: 10px;
}

@keyframes fadeDown {
    from {
        opacity: 0;
        transform: translateY(-20px); /* Initial position above the container */
    }
    to {
        opacity: 1;
        transform: translateY(0); /* Final position at the center of the container */
    }
}