.career-main {
    font-family: 'Sen', Sans-serif;
  }
  
  
.career-body{
    background-color: #F7F5FF;
}

.know-more-container-list-items-text{
    color: black;
}

/* .careertext{
    color: #506690;
} */

.career-section1-right-text{
    color: #506690;
}

.career-section2-left-text{
font-weight: 400;
font-style: italic;
}

.career-section1-right-header, .career-section2-left-header,.career-section3-left-header{
line-height: 1.2em;
font-weight: 700;
}

.border-top-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
    background-color: purple;
  }

  /* In your CSS file, e.g., MyComponent.css */
@keyframes slideInFromBottom {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

/* In your CSS file, e.g., MyComponent.css */
@keyframes slideInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInSlowly {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .fade-in-slow {
    animation: fadeInSlowly 2s ease-in-out;
  }


  .animate-from-top {
    animation: slideInFromTop 1s ease-in-out;
  }

  
  .animate-from-bottom {
    animation: slideInFromBottom 1s ease-in-out;
  }
  
  
@keyframes fadeLeftText {
  from {
      opacity: 0;
      transform: translateX(-30%);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeRightText {
  from {
      opacity: 0;
      transform: translateX(30%);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;        /* Start with opacity 0 */
  }
  to {
    opacity: 1;        /* End with opacity 1 */
  }
}

.fade-in1 {
  opacity: 0;          /* Initially set opacity to 0 */
  animation: fadeIn 1.5s ease-in-out forwards;  /* Use a CSS animation */
}

.fade-in2 {
  opacity: 0;          /* Initially set opacity to 0 */
  animation: fadeIn 1.5s ease-in-out forwards;  /* Use a CSS animation */
}

.fade-in3 {
  opacity: 0;          /* Initially set opacity to 0 */
  animation: fadeIn 1.5s ease-in-out forwards;  /* Use a CSS animation */
}

.fade-in4 {
  opacity: 0;          /* Initially set opacity to 0 */
  animation: fadeIn 2.5s ease-in-out forwards;  /* Use a CSS animation */
}

.fade-in5 {
  opacity: 0;          /* Initially set opacity to 0 */
  animation: fadeIn 2.5s ease-in-out forwards;  /* Use a CSS animation */
}
.fade-in6 {
  opacity: 0;          /* Initially set opacity to 0 */
  animation: fadeIn 2.5s ease-in-out forwards;  /* Use a CSS animation */
}

.slide-in-right {
  opacity: 0;                   /* Initially set opacity to 0 */
  transform: translateX(50%);  /* Initially position off-screen to the right */
  animation: slideInRight 2s ease-in-out forwards; /* Use a CSS animation */
}

@keyframes slideInRight {
  from {
    opacity: 0;                  /* Start with opacity 0 */
    transform: translateX(100%); /* Start off-screen to the right */
  }
  to {
    opacity: 1;                  /* End with opacity 1 */
    transform: translateX(0);    /* End at the original position (0) */
  }
}






