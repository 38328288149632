/* first font */
@font-face {
    font-family: 'BC Novatica CYR Regular';
    src: url('../src/Assets/fonts/Novatica/NovaticaLight.ttf') format('truetype');
    /* Add additional font formats if necessary */
  }
  
/* Second font */
@font-face {
  font-family: 'Poppins';
  src: url('../src/Assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  /* Additional font formats and properties as needed */
}

body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif !important;
    background-color: #1d0f33 !important;
    /* background-color: "#6c41ff" !important; */
    box-sizing: inherit;
}