.infiniteslider {
    /* background: white; */
    /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .infiniteslider::before,
  .infiniteslider::after {
    /* background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); */
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }
  
  .infiniteslider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  
  .infiniteslider::before {
    left: 0;
    top: 0;
  }
  
  .infiniteslide-track {
    animation: scroll 80s linear infinite;
    display: flex;
    width: calc(250px * 84);
  }
  
  .infiniteslide {
    height: 100px;
    width: 250px;
    padding: 0px 15px;
    opacity: 0.7;
  }
  
  @keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-250px * 42)); }
  }
  