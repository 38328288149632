.casestudy-main {
    font-family: "Poppins", sans-serif;
    /* background: linear-gradient(to top, #f7f5ff 45%, #1d0f33 38%, #1d0f33 33%); */
}

.casestudy {
    padding: 30px 60px;
    /* background-color: #f7f5ff; */
    display: flex;
    flex-direction: column;
    gap: 80px;
    font-family: "'Sen', sans-serif";
}

.main-section {
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    gap: 100px;
}

.left-main-section {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.left-section-desc {
    font-weight: bold;
    line-height: 1.2em;
    /* font-size: 0.8rem; */
}

.info-tags {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 20px;
}

.image-main-section {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.sections {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.section-navbar {
    display: flex;
    /* justify-content: space-around; */
    /* color: black; */
    justify-content: center;
    align-items: center;
    gap: 50px;
    font-size: 1.8rem;
    font-weight: bolder;
}

.section-navbar a {
    color: black !important;
}

.section-navbar a:hover {
    color: #6c41ff !important;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.cardcasestudy {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
   
}

.cardcasestudyHeader {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 800;
    color: #6c41ff;
}

.cardcase {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-top: 50px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 992px) {
    .cardcasestudy {
        display: flex;
        flex-direction: column;
    }
    .section-navbar {
        display: none;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (max-width: 1200px) {
    .main-section {
        display: flex;
        flex-direction: column;
    }
    .casestudy {
        padding: 20px 70px;
    }
    .left-section-desc {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
    .image-main-section {
        width: 70%;
        margin: 0 auto;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .left-section-desc {
        font-size: 2.4rem;
    }
}

@media only screen and (max-width: 600px) {
    .info-tags {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .casestudy-main {
        background: linear-gradient(to top, #f7f5ff 50%, #1d0f33 38%, #1d0f33 33%);
    }
}

@media screen and (max-width: 767px) {
    .casestudy {
        padding: 20px;
    }
}