.services-each-main {
  font-family: "Poppins , sans-serif",
}


.services-body {
  background-color: #F7F5FF;
}

.services-section1-right-text {
  color: #506690;
}

.services-section3-left-text {
  font-weight: 400;
  font-style: italic;
}

.services-section1-header,
.services-section3-left-header {
  line-height: 1.2em;
  font-weight: 700;
}

.animatedNumber {
  font-size: 1rem;
  text-align: center;
  color: black;
  /* Change the color to black */
  /* Add other styles as needed */
}

/* In your CSS file, e.g., MyComponent.css */
@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* In your CSS file, e.g., MyComponent.css */
@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInSlowly {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-in-slow {
  animation: fadeInSlowly 2s ease-in-out;
}


.animate-from-top {
  animation: slideInFromTop 1s ease-in-out;
}


.animate-from-bottom {
  animation: slideInFromBottom 1s ease-in-out;
}

.slide-from-left {
  animation: slideFromLeft 1.5s ease-out;
  /* Adjust duration and timing function as needed */
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-from-right {
  animation: slideFromRight 1.5s ease-out;
  /* Adjust duration and timing function as needed */
}

.services-section2-left-header {
  font-size: 2.9rem;
  width: 60%;
  font-weight: 600;
  margin: 0 auto;
}

.services-section2-header {
  font-size: 2.9rem;
}

.services-section2-desc {
  width: "60%";
  margin: "0 auto";
  font-size: "1.1rem";
}

@media (max-width: 767px) {

    .services-main{
      padding-top: 25px;
    }

  .services-section2-left-header {
    margin: 0px;
    width: 100%;
    font-size: 2rem;

  }

  .services-section2-header {
    width: 100%;
    font-size: 2rem;
  }
/* 
  .services-section2 {
    padding: 0px !important;
  }

  .service-section2 p {
    width: 100% !important;
  } */

}