.joinus:hover {
    border-radius:0px 0px 5px 5px;
    background-color: #6C41FF;
  
}

.joinus:hover a {
    color: #FFFFFF !important;

}

.ourteam:hover {
    border-radius: 5px 5px 0px 0px;
    
    background-color: #6C41FF;
    color: #FFFFFF;
}

.ourteam:hover a {
    color: #FFFFFF !important;

}

.airesponse:hover {
    border-radius: 0 0 5px 5px !important;    
    background-color: #6C41FF;
}

.airesponse:hover a {
    color: #FFFFFF !important;

}

.knowmore:hover {
    border-radius: 5px 5px 0 0 !important;    
    background-color: #6C41FF;
}

.knowmore:hover a {
    color: #FFFFFF !important;

}
