.know-more-container-text h2 {
    font-size: 2.7rem;
}

@media (max-width: 767px) {
    .homesection4text {
        padding: 0 !important;
    }
    .know-more-container-text h2 {
        font-size: 2rem;
    }
}