/* Custom Styles */
.main {
  width: 100%;
  display: flex;
  flex-direction: column;
}


.textarea {
  width: 100%;
  margin-top: 10px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid white;
  resize: none;
  outline: none;
  box-sizing: border-box;
}

.response-container {
  font-family: "Poppins", sans-serif;
  font-size: smaller;
  width: 102.5%;
  margin-top: 2px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  color: black;
  overflow-y: auto;
  max-height: 400px;
}

.copy-button {
  cursor: pointer;
  font-size: 16px;
  color: grey;
  transition: transform 0.1s, box-shadow 0.1s;
  display: flex;
  justify-content: flex-end;
}

.copy-button:active {
  transform: scale(0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.second-section {
  background-color: rgb(247, 245, 255);
  width: 100%;
}

.textsection {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.heading-main {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  color: rgb(0, 0, 0);
  font-size: 35px;
}

.about-ai {
  margin-top: 2%;
  line-height: 21px;
  font-size: 18px;
  color: rgb(0, 0, 0);
  margin-bottom: 7vh;
}

