
.policies-container {
    max-width: 2050px;
    margin: 0 auto;
    display: flex;
    background-color: "#F7F5FF";
    /* margin-top: 20px; */
    /* background-color: #A6ACC9; */
  }
  
  .policies-container-main {
    max-width: 1000px;
    padding: 16px;
    border-radius: 0px;
    margin-left: 8px;
    /* margin-top: 20px; */
    background-color: "#F7F5FF";
    border-right: 0.5px solid #202021;
    border-left:0.5px solid #202021;;
  
  }
  
  .policies-nav {
    width: 320px;
    min-width: 220px;
    font-size: 12.8px;
    padding: 16px;
    margin: 8px;
    align-self: flex-start;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 48px;
    max-height: calc(100vh - 70px);
    overflow: auto;
    
  }
  
  @media screen and (max-width: 900px) {
    .policies-nav {
      display: none;
      
    }
  }

  .policiesheadings{
    color:#202021 !important;
  }
  
  .policiesheadingsdesc {
    /* color: #202021 !important; */
    /* text-decoration: none; */
  }
  
  li.active > a {
    color: grey;
  }
  
  li > a:hover {
    color: grey;
  }
  
  /*  Gives a little bit of buffer when header is navigated to */
  .policiesh2, .policiesh3{
        padding-top: 16px;
      margin-top: -16px;
  }

 .policies-container-main p{
  font-size: 0.8rem;
 }

 .policiesh3{
  font-size: 1rem;
 }

 .policiesh2{
  font-size: 1.2rem;
 }