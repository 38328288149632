.home-main {
    margin: 0;
    padding: 0;
}

ul>li {
    list-style: none;
}

a:link {
    text-decoration: none;
}

.blue-button-1 {
    background: #3e67e3;
    border: 0.063px solid #3e67e3;
    border-radius: 1.875rem;
    padding: 0.75rem 2rem;
    color: white;
    font-size: 1rem;
}

.blue-button-0 {
    background: #3e67e3;
    border: 0.063px solid #2c3444;
    border-radius: 1.875rem;
    padding: 0.5rem 2rem;
    color: white;
    font-size: 1rem;
}

.grey-button-0 {
    background: #2c3444;
    border: 0.063px solid #2c3444;
    border-radius: 1.875rem;
    padding: 0.5rem 2rem;
    color: white;
    font-size: 1rem;
}

.center-the-element {
    text-align: center;
}

.center-element {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wd {
    width: 100%;
}

header {
    position: fixed;
    width: 100%;
    z-index: 100;
}

.logo {
    color: white;
    fill: white;
    height: 70%;
}

.header-container {
    background: #151b28;
    width: 100%;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
}

.header-right-container {
    display: flex;
    gap: 25px;
}

.navbar-link {
    color: #437ef7;
}

.hero {
    text-align: center;
    background: #151b28;
    color: white;
}

.hero-container {
    text-align: center;
    padding-top: 7rem;
    margin: auto;
}

.hero-container>p {
    padding-top: 2rem;
    font-weight: lighter;
    font-size: 20px;
    color: white;
}

.hero-container>p>strong {
    text-decoration: underline;
    color: white;
}

.section-container {
    max-width: 81.125rem;
    width: 80%;
    padding-top: 1.25px;
    margin: auto;
}

.section-text>p {
    max-width: 45rem;
    padding-top: 0.9rem;
    font-size: 1.125rem;
    color: rgba(95, 109, 126, 1);
    line-height: 1.5rem;
    width: 60%;
    margin: auto;
}

.section-text>h1 {
    font-size: 2.25rem;
    color: black;
}

.partners {
    text-align: center;
    background: #f8f9fb;
}

.partner-logo-grid {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.testimonials {
    text-align: center;
    background: #f8f9fb;
    padding: 4rem 0;
}

.testimonials-text-header {
    display: flex;
}

.testimonials-text-header-left {
    display: flex;
}

.circle-icon {
    width: 40px;
    height: 40px;
    background-color: #f8f9fb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.testimonials-text-header-left-image-container {
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

.testimonials-text-column {
    width: 8rem;
}

.testimonials-text-header .testimonials-text-header-left>h3 {
    text-align: left;
    font-size: 1rem;
    color: #9397a2;
    padding-top: 0.85rem;
    padding-left: 1rem;
}

.testimonials-text-column {
    border-radius: 1.5rem;
}

.testimonials-text>p {
    text-align: left;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.9rem;
    color: #616777;
}

.testimonials-text-header-highlight {
    color: #2c5085;
    font-weight: 700;
}

.testimonials-text>h4 {
    padding-top: 2rem;
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.325rem;
    color: #5f6d7e;
    bottom: 0;
}

.testimonials-grid {
    background: white;
}

.testimonials-grid-adjustment {
    padding-top: 3rem;
}

.testimonials-text {
    padding: 2rem;
}

.know-more-section {
    color: white;
    background: #151b28;
    padding: 4rem 0;
}

.aligh-item-center {
    display: flex;
    align-items: center;
}

.know-more-container-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.know-more-container-text>h1 {
    line-height: 2.625rem;
    max-width: 35rem;
}

.know-more-container-text>ul {
    padding: 1.5rem 0rem;
}

.know-more-container-text>h1>span {
    font-weight: 200;
}

.know-more-container-text>ul>li {
    gap: 10px;
    display: flex;
    padding: 0.25rem 0rem;
    font-weight: 150;
}

.default-icons-background {
    display: flex;
    align-self: center;
    padding: 0.5rem;
    background: #437ef7;
    border-radius: 50%;
}

.default-icons-background>img {
    height: 1rem;
    width: 1rem;
}

.know-more-container-text-input {
    max-width: fit-content;
    background: #2c3444;
    /* padding: 0.75rem; */
    border-radius: 6px 0px 0px 6px;
}

.know-more-input-button-container {
    padding-top: 2rem;
}

.know-more-container-text-input>input {
    outline: none;
    padding-left: 2rem;
    background: #2c3444;
    border: none;
    color: white;
}

.know-more-container-list-items-text {
    padding-left: 1rem;
}

.know-more-input-button-container>button {
    background: #437ef7;
    color: white;
    border: none;
    padding: 0.7rem 1rem;
    border-radius: 6px 6px 6px 6px;
}

.know-more-container-text>hr {
    background: #384255;
}


/* Invest */

.invest {
    padding-bottom: 2.5rem;
}

.invest-container-list-items {
    color: white;
}

.invest-container-list-items>li {
    display: flex;
    padding-top: 1rem;
    align-items: center;
}

.invest-container-list-items-text {
    padding-top: 0.5rem;
    padding-left: 1rem;
}

.invest-container-list-items-text:hover {
    color: white;
}

.invest-icons-background:hover {
    background: #437ef7;
}

.invest-icons-background {
    width: 3rem;
    padding: 1rem;
    background: #384255;
    border-radius: 1.875rem;
}

.hire {
    padding: 3rem 0rem;
    position: relative;
    background: #2b63d9;
    color: white;
}

@media (max-width: 600px) {
    .hire-img {
        max-height: 300px;
        max-width: 300px;
    }
}

@media only screen and (min-width: 845px) and (max-width: 1090px) {
    .hire-img {
        max-height: 290px;
        max-width: 300px;
    }
}

.career-restaverse {
    font-size: 1.7rem;
    font-weight: 400;
}

.career-restaverse>a>span {
    text-decoration: underline;
    font-weight: 500;
    color: #fff;
}

.section-container-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hire-section-container-text-heading5 {
    font-weight: 200;
}

.hire-section-container-text-heading1 {
    max-width: 30rem;
    line-height: 2.625rem;
}

.hire-button {
    color: white;
    border: none;
    background: #437ef7;
    padding: 1rem 2rem;
    border-radius: 0.375rem;
}

.hire-button-container {
    padding-top: 3rem;
}


/* Restaverse-Footer */

.restaverse-footer {
    background: #151b28;
    color: white;
}

.footer-container {
    padding-top: 2rem;
    /* padding-bottom: 4rem; */
}

.footer-container-first-row {
    display: flex;
    justify-content: space-around;
    padding-bottom: 1rem;
    place-items: center;
}

@media (max-width: 600px) {
    .footer-container-first-row {
        flex-direction: column;
        text-align: center;
    }
    .footer-first-row-responsive-container {
        padding-top: 1.5rem;
    }
    .footer-container-navigation-button-container {
        display: flex;
        justify-content: center;
    }
}

.footer-first-row-responsive-container {
    float: right;
}

.footer-container-first-row>h2 {
    width: 30%;
}

.footer-container-first-row-mobile {
    flex: 1 auto;
}

.footer-container-middle-section {
    padding-bottom: 1.5rem;
}

.footer-container-hr-icons {
    display: flex;
    list-style: none;
}

.footer-container-middle-Section>h2 {
    color: #f9f9f9;
    font-weight: 700;
    padding-bottom: 0.75rem;
    font-size: 1rem;
    padding-left: 2rem;
    padding-top: 2rem;
}

.footer-container-middle-section>ul>li {
    padding-top: 0.5rem;
}

.footer-restaverse-heading {
    font-size: 1.75rem;
    font-weight: 400;
}

.footer-restaverse-heading-container {
    padding-top: 1rem;
}

.footer-container-navigation-first-container {
    gap: 1rem;
    gap: 1rem;
}

.footer-container-hr-icons-buttons {
    font-size: 0.934rem;
    padding: 0.825rem 1.5rem;
    border-radius: 0.375rem;
    border: none;
    color: white;
    background: #437ef7;
}

.footer-container-hr-icons-buttons-gray {
    background: #2c3444;
}

.footer-container-last-row {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-container-navigation-last-container {
    display: flex;
}

.restaverse-copyright-container {
    width: 50%;
    /* float: left; */
}

@media screen and (max-width: 600px) {
    .copyright-container {
        display: block;
        padding-top: 0.2rem;
    }
    .copyright-border {
        display: none;
    }
}

.footer-container-icon {
    padding: 0 1rem;
}

.restaverse-copyright {
    color: #a5acba;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
}

.footer-container-middle-section>ul>li>a {
    color: #a5acba;
}


/* /slid-logos */

@media (max-width: 37.5rem) {
    .image-container {
        flex: 0 0 6.25rem !important;
    }
}

@keyframes slide_logos {
    0% {
        transform: translateX(0);
    }
    100% {
        /* transform: translateX(-1200%); */
    }
}

.flipper-container {
    color: white;
    display: flex;
    flex-wrap: nowrap;
    /* align-self: center; */
    /* justify-content: center; */
    padding-top: 2rem;
    font-size: 1.25rem;
    /* text-align: center !important; */
}

@media (max-width: 50rem) {
    .flipper-container {
        flex-wrap: wrap;
    }
    .cubespinner div {
        /* min-width: 100vw !important; */
        /* text-align: center !important;
        justify-content: center !important; */
        padding-top: -1.1rem !important;
    }
}

.cubespinner {
    overflow: hidden;
    max-height: 4rem;
}

.cubespinner div {
    padding-bottom: 1.2rem;
    padding-top: 10px;
    display: flex;
    gap: 8rem;
    /* align-items: center; */
    /* justify-content: start; */
    text-align: left;
    height: 100%;
    /* padding-left: 8px; */
    min-height: 5rem;
    animation: spin_words 9s infinite;
}

.cubespinner div {
    color: "purple !important";
    /* font-size: 1.5rem; */
    font-weight: bolder;
}

@keyframes spin_words {
    8% {
        transform: translateY(-115%);
    }
    20% {
        transform: translateY(-110%);
    }
    28% {
        transform: translateY(-215%);
    }
    40% {
        transform: translateY(-210%);
    }
    48% {
        transform: translateY(-315%);
    }
    60% {
        transform: translateY(-310%);
    }
    68% {
        transform: translateY(-415%);
    }
    80% {
        transform: translateY(-410%);
    }
    88% {
        transform: translateY(-515%);
    }
    100% {
        transform: translateY(-510%);
    }
}

.slider {
    min-height: 20vh;
    margin: auto;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.slider-track {
    flex: 0 0 20vh;
    justify-content: space-around;
    display: flex;
    align-items: center;
    width: calc(12rem * 42);
}

@media (max-width: 37.5rem) {
    .slider-track {
        flex: 0 0 6.25rem !important;
    }
}

.slide {
    flex: 0 0 20vh;
    margin-right: 2rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    animation: scroll 42s linear infinite;
}

.slide>img {
    width: 100%;
    height: 100%;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-15.625rem * 26));
    }
}


/* Modal */


/* Modal */

.bg-modal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    /* justify-content: center; */
    align-items: center;
    display: none;
    overflow: hidden;
    z-index: 1000;
    scrollbar-width: none;
    /* Firefox - */
    -ms-overflow-style: none;
    /* Internet Explorer and Edge */
}

@media only screen and (max-width: 600px) {
    .modal-content-container {
        max-width: 90% !important;
        margin: 0 auto !important;
        overflow-y: auto !important;
    }
}

.modal-content-container {
    max-width: 30rem;
    min-height: 40rem;
    background-color: white;
    opacity: 1;
    color: white;
    border-radius: 30px;
    padding: 1.4rem;
    position: relative;
    overflow: hidden;
    max-height: 25rem;
    overflow-y: hidden;
}

.modal-content-container::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
}

.form-container {
    text-align: center;
}

.form-header {
    text-align: left;
    max-width: 20rem;
    line-height: 2.5rem;
    font-size: 1.8rem;
    font-weight: 400;
    color: #30384c;
}

.modal-checkbox {
    padding: 2rem;
}

.user-phone-modal {
    width: 100%;
    padding-bottom: 1rem;
}

.modal-textarea {
    min-height: 6rem !important;
}

.input-form {
    min-height: 3rem;
    border: 1px solid #b2b5be;
    border-radius: 30px;
    margin-bottom: 30px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 20px;
}


/* iti-phone classes */

.iti__country-name {
    color: black;
}

.iti {
    width: 100%;
}

.iti__selected-flag {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 6px 0 2rem;
}


/* iti-phone classes--ends */

.user-phone-input {
    height: 38px;
    color: #333;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857;
    display: block;
    outline: none;
}

.modal-checkbox-text {
    color: black;
    font-size: 0.9rem;
    max-width: 100%;
}

.checkbox-container-modal {
    display: flex;
    align-items: baseline;
    gap: 0.3rem;
}

.checkbox-radio {
    position: relative;
    top: 0.1rem;
}

.w-input {
    width: 100%;
    height: 38px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857;
    display: block;
    outline: none;
}

.form-detail {
    max-width: 30rem;
    margin-top: 20px;
}

form {
    display: block;
    margin-top: 0em;
}

@media screen and (max-width: 767px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.container {
    z-index: 3;
    max-width: 1340px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    position: relative;
}

.description-input {
    padding: 1rem;
    height: 7rem;
}

.wrap-submit-btn {
    cursor: not-allowed;
    border: 1px solid black;
    background: #e5e5e5;
    width: 100%;
    min-height: 3rem;
    color: #929292;
    text-align: center;
    margin-top: 20px;
    display: block;
    position: relative;
    border-radius: 30px;
    outline: none;
}

.modal-close {
    position: absolute;
    color: black;
    padding: 0 2rem;
    font-size: 2.5rem;
    top: -10px;
    right: 15px;
    cursor: pointer;
    transform: rotate(45deg);
}

.validation {
    color: black;
}

.iti--separate-dial-code .iti__selected-dial-code {
    margin-left: 6px;
    color: black;
}

.iti--separate-dial-code .iti__selected-flag {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.iti__flag-container {
    display: flex;
    flex-direction: row;
}

.iti__selected-dial-code {
    order: 1;
    padding-right: 0.4rem;
}

.iti__country {
    max-width: 24rem;
    /* padding-top: 5rem; */
}

.iti__country-list {
    top: 100%;
    bottom: auto;
    margin-top: 10px;
}

.iti__flag {
    order: 2;
}

.iti__arrow {
    order: 3;
}


/* testimonial - carousel */

.carousel-parent-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0 35px;
    background-color: white;
}

.carousel .testimonial-col-card {
    list-style: none;
    scroll-snap-align: start;
}

.testimonial-col-card {
    max-width: 40rem;
}

.carousel.dragging .card {
    user-select: none;
    cursor: grab;
}

.carousel.dragging {
    scroll-snap-type: none;
    scroll-behavior: auto;
}

.wrapper {
    width: 100%;
    position: relative;
}

.testimonial-nav-btn {
    display: flex;
    justify-content: center;
    padding: 1.4rem 0px;
    gap: 1rem;
}

.testimonial-nav-btn i {
    display: grid;
    place-items: center;
    height: 50px;
    width: 50px;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.25rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.wrapper .carousel {
    gap: 15px;
    display: grid;
    overflow: hidden;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% / 3) - 10px);
    scroll-behavior: smooth;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: 0;
}

.carousel.no-transition {
    scroll-behavior: auto;
}

.carousel::-webkit-scrollbar {
    display: none;
}

.testimonials-text-column-container {
    border: #dee2e6 1px solid;
    border-radius: 20px;
}

.testimonials-footer {
    display: flex !important;
    gap: 1rem;
    padding-top: 1rem;
}

.founder-container {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0.4rem;
}

.founder-name {
    margin: 0px;
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.325rem;
    color: #5f6d7e;
}

.founder-designation {
    margin: 0px;
    text-align: left;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.325rem;
    color: #5f6d7e;
    opacity: 0.8;
}

@media screen and (max-width: 650.5px) {
    .wrapper .carousel {
        grid-auto-columns: 100%;
    }
}

@media screen and (min-width: 651px) and (max-width: 991.5px) {
    .wrapper .carousel {
        grid-auto-columns: calc((100% / 2) - 8px);
    }
}

@media screen and (min-width: 1580px) {
    .wrapper .carousel {
        grid-auto-columns: calc((100% / 4) - 8px);
    }
}


/* read-more */

.testimonials-footer {
    display: block;
}

.more-content {
    display: none;
}

.more-content.show {
    display: inline;
}

.myBtn {
    display: inline-block;
    text-decoration: none;
    /* margin-left: 5px;  */
    cursor: pointer;
}

.dots {
    display: inline-block;
}

.job-desc {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 7rem;
    padding-bottom: 2rem;
}

.underline {
    text-decoration: underline;
}

@media (max-width: 600px) {
    .logo {
        width: 90%;
    }
    .blue-button-0 {
        font-size: 1rem;
        padding: 0.3rem 0.5rem;
    }
    .restaverse-copyright {
        display: flex;
        flex-direction: column;
    }
}


/* Modal */

.modal-form-container {
    text-align: center;
}

.input-form {
    min-height: 3rem;
    border: 1px solid #b2b5be;
    border-radius: 30px;
    margin-bottom: 20px;
    padding-left: 20px;
}

.btn {
    display: flex;
    margin: 10px;
}

.submit-btn {
    background: #8635fd;
    border-radius: 5px;
    border: 2px #8635fd solid;
    padding: 15px;
    color: white;
    font-size: 16px;
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-contents {
    width: 40% !important;
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.5s ease-in-out;
}


/* @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} */

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover {
    color: #333;
}

@media (max-width: 767px) {
    .homesection2 {
        padding-top: 30px;
    }
    /* .homesection4 {

        padding-bottom:35px !important ;
        padding-top:45px ;

    } */
    .homeindusrtyleaders .partners .section-text br {
        display: none;
    }
    .homeindusrtyleaders .partners .section-text h2 {
        margin-bottom: 0px;
        font-size: 1.7rem;
    }
    .homeindusrtyleaders .partners .slider {
        padding-top: 0px;
    }
    .homeindusrtyleaders .partners {
        padding-bottom: 0px !important;
    }
    .homeindusrtyleaders div {
        padding-top: 6px;
        padding-bottom: 10px;
    }
    .homesection4 .section-container .row .know-more-container-text {
        margin-top: 15px;
    }
    .homesection4 .section-container .row .aligh-item-center {
        margin-top: 10px;
    }
    .hometestimonialgrid {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}