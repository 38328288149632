/* Box container */
.responsive-box {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 16px; 
    flex-direction: row;
    
  }
  
  @media (max-width: 600px) {
  
    .responsive-box {
      flex-direction: column;
    }
  }
  
 
  .marketing-text {
    font-family: 'Baskerville, serif';
    

  }
    
  @media (max-width: 600px) {
   
    .insights-text {
      margin-left: 0;
      margin-top: 8px;
    }
  }
  .divider{
    margin-top: 50px;
    background-color: rgb(108, 65, 255);
    height: 3px;
    margin-left: -1%;
    margin-top: 2%; 
}